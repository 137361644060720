<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("suppliers.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("suppliers.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("suppliers.configuration") }}
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(1) && withPermissionName(permissions.CAT_SUPP_ADD_01)
              "
              @click="changeAction(2)"
            >
              <i class="ti-plus mr-2"></i> {{ $t("global.create_supplier") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-clipboard mr-2"></i
                      >{{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>

              <div v-show="showAction(1)">
                <TableComponent
                  ref="tableSupp"
                  :headers="headers"
                  :isButton="true"
                  @excelTable="excelTable"
                  @updateRecord="edit"
                  :isSupplier="true"
                  :isUpdate="withPermissionName(permissions.CAT_SUPP_EDIT_02)"
                />
              </div>

              <div v-if="showAction(2) || showAction(3)">
                <div class="card">
                  <div class="card-body">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="doAction">
                        <div class="row">
                          <div class="col-md-12 pb-0">
                            <h6>
                              <v-icon class="pb-1"
                                >mdi-numeric-1-box-outline</v-icon
                              >
                              {{ $t("suppliers.information_suppliers") }}
                            </h6>
                          </div>
                          <div class="col-md-12 pb-0">
                            <validation-provider
                              v-slot="{ errors }"
                              name="name"
                              rules="required|max:200"
                            >
                              <v-textarea
                                v-model="model.name"
                                :counter="200"
                                maxlength="200"
                                :error-messages="errors"
                                :label="$t('label.name')"
                                required
                                outlined
                                rows="3"
                                class="br"
                                oninput="this.value = this.value.toUpperCase()"
                                autocomplete="off"
                              ></v-textarea>
                            </validation-provider>
                          </div>
                          <div class="col-md-12 pt-0">
                            <validation-provider
                              v-slot="{ errors }"
                              name="rfc"
                              rules="required|max:50"
                            >
                              <v-text-field
                                v-model="model.supplierKey"
                                :counter="50"
                                maxlength="50"
                                :error-messages="errors"
                                :label="$t('label.RFC')"
                                oninput="this.value = this.value.toUpperCase()"
                                required
                                autocomplete="off"
                              ></v-text-field>
                            </validation-provider>
                          </div>
                          <div class="col-md-12 text-right">
                            <button
                              class="btn btn-outline-success"
                              type="submit"
                              :disabled="invalid || sending"
                            >
                              <i class="feather icon-save mr-1"></i>
                              {{
                                model.idSupplier
                                  ? $t("global.update")
                                  : $t("global.save")
                              }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/common/table/TableComponent";
import { UtilFront, PermissionConstants } from "@/core";
import { SupplierRequest, ExcelRequest } from "@/core/request";
import { SUPPLIERS } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      title: "suppliers.suppliers",
      remmisionList: [],
      list: [],
      headers: SUPPLIERS(),
      search: "",
      action: 1,
      sending: false,
      model: {
        name: "",
        supplierKey: "",
        idSupplier: "",
        hasBrand: null,
      },
      permissions: PermissionConstants,
    };
  },
  components: {
    TableComponent,
  },
  created() {
    this.listAll();
  },
  computed: {
    titleAction() {
      if (this.action == 1) {
        return "suppliers.suppliers";
      } else if (this.action == 2) {
        return "suppliers.register_suppliers";
      } else if (this.action == 3) {
        return "suppliers.edit_suppliers";
      }
      return "Listado";
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.action = action;
      } else {
        this.action = action;
      }
    },
    async doAction() {
      switch (this.action) {
        case 2:
          this.add();
          break;
        case 3:
          const hasBranch = this.model.hasBranch;
          if (hasBranch) {
            const { isConfirmed } = await this.$SwalConfirm.fire({
              icon: "warning",
              html:
                "La información de este proveedor está asociada a una sucursal. Si se modifica el proveedor, la información correspondiente en la sucursal también se actualizará.",
            });
            if (isConfirmed) {
              this.update();
            }
          } else {
            this.update();
          }
          break;
      }
    },
    listAll() {
      this.loading = UtilFront.getLoadding();
      SupplierRequest.listAll()
        .then((res) => {
          let { objects } = res.data.data.data;
          if (objects) {
            this.$refs.tableSupp.reloadComponent(objects);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    add() {
      this.sending = true;
      SupplierRequest.add({
        name: this.model.name,
        supplierKey: this.model.supplierKey,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: 1,
        },
      })
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success == true) {
            this.listAll();
            this.changeAction(1);
            this.alert("success", "Add_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    update() {
      this.sending = true;
      SupplierRequest.update({
        idSupplier: this.model.idSupplier,
        name: this.model.name,
        supplierKey: this.model.supplierKey,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: 1,
        },
      })
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success == true) {
            this.listAll();
            this.changeAction(1);
            this.alert("success", "Update_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    withPermission(numRol) {
      return UtilFront.withPermission(numRol);
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    edit(object) {
      this.model = { ...object };
      this.changeAction(3);
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportSupplier({});
    },
  },
  watch: {
    "$i18n.locale": function() {
      this.headers = SUPPLIERS();
    },
  },
};
</script>

<style>
/* .theme--light.v-messages {
  color: rgb(255 0 0);
} */
</style>
