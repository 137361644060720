<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      fixed-header
      height="auto"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              dense
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-if="isSupplier" v-slot:[`item.hasBranch`]="{ item }">
        <div v-if="item.hasBranch">
          <i class="mdi mdi-check-circle success-color"></i>
        </div>
        <div v-else>
          <span>-</span>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="isUpdate" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="updateRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi mdi-square-edit-outline
            </v-icon>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>
        <div v-if="!isUpdate">
          <span>-</span>
        </div>
      </template>
    </v-data-table>

    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";

export default {
  props: {
    headers: {},
    isDelete: false,
    isUpdate: false,
    isButton: false,
    isSupplier: false,
  },
  data() {
    return {
      search: "",
      dataList: [],
      permissions: PermissionConstants,
    };
  },
  components: {
    ButtonTableComponent,
  },
  methods: {
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
  },
};
</script>

<style scoped>
.success-color {
  color: #4caf50 !important;
  font-size: 22px;
}
</style>
